// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";

import errorIllustration from "../assets/404_error.png";

import Layout from "../container/Layout";
import {SEAFOAM_5, VERY_LIGHT_GREY} from "../styles/colors";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../styles/sizes";
import {h2, p} from "../styles/typography";

function PageNotFound(): React.Node {
  const intl = useIntl();
  return (
    <Layout slug="404">
      <div className={css(styles.container)}>
        <div className={css(styles.left)}>
          <h2 className={css(styles.title)}>
            {intl.formatMessage({id: "404_title"})}
          </h2>
          <p className={css(styles.body)}>
            {intl.formatMessage({id: "404_body"})}
          </p>
          <a href="/" className={css(styles.link)}>
            &#x2190; {intl.formatMessage({id: "404_CTA"})}
          </a>
        </div>
        <div className={css(styles.right)}>
          <img
            className={css(styles.img)}
            src={errorIllustration}
            alt="404 error illustration"
          />
        </div>
      </div>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    backgroundColor: VERY_LIGHT_GREY,
    minHeight: "calc(100vh - 105px)",
    boxSizing: "border-box",

    padding: `50px ${SIDE_MARGIN_MOBILE}`,
    flexDirection: "column-reverse",
    justifyContent: "flex-end",

    [BREAKPOINTS.DESKTOP]: {
      padding: `10vh ${SIDE_MARGIN}`,
      flexDirection: "row",
      justifyContent: "flex-start",
    },

    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `10vh ${SIDE_MARGIN_WIDE}`,
    },
  },
  left: {
    width: "100%",
    padding: "30px 0px",

    [BREAKPOINTS.DESKTOP]: {
      width: "50%",
      paddingTop: "calc(10vh + 185px)",
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    width: "100%",
    padding: 0,
    alignItems: "center",

    [BREAKPOINTS.DESKTOP]: {
      paddingTop: "10vh",
      width: "50%",
      alignItems: "flex-end",
    },
  },
  title: {
    ...h2,
    margin: "0px",
  },
  body: {
    ...p,
    padding: "20px 0px",
  },
  link: {
    ...p,
    color: SEAFOAM_5,
    textDecoration: "none",
  },
  img: {
    width: "60%",

    [BREAKPOINTS.DESKTOP]: {
      width: "100%",
    },
  },
});

export default PageNotFound;
